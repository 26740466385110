<template>
	<div class="home">
		<BannerHome />
		<ProdukHome />
		<MixProdukHome />
		<ArtikelHome />
		<Subscribe />
	</div>
</template>

<script>
import BannerHome from "@/components/BannerHome.vue";
import ProdukHome from "@/components/ProdukHome.vue";
import MixProdukHome from "@/components/MixProdukHome.vue";
import ArtikelHome from "@/components/ArtikelHome.vue";
import Subscribe from "@/components/Subscribe.vue";

export default {
	name: "Home",
	components: {
		BannerHome,
		ProdukHome,
		MixProdukHome,
		ArtikelHome,
		Subscribe,
	},
};
</script>
