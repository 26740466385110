<template>
	<div class="wishlist">
        <Wishlist />
	</div>
</template>

<script>
import Wishlist from "@/components/Wishlist.vue"

export default {
	name: "PageWishlist",
	components: {
        Wishlist
	},
};
</script>

<style></style>
