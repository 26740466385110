<template>
	<div class="update">
		<UpdateContent />
	</div>
</template>

<script>
import UpdateContent from "@/components/UpdateContent.vue";

export default {
	name: "Update",
	components: {
		UpdateContent,
	},
};
</script>

<style></style>
