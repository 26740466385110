<template>
	<div class="container-fluid mt-5">
		<div class="container">
			<h3 class="bold-stella text-center" style="color:black">Voucher</h3>
			<div class="Voucher">

			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Voucher",
	data() {
		return {
			// produkAllNew: [],
			// allProdukSearch: "",
			// loading: true,
			// afterLoading: false,
		};
	},
	methods: {
		scroollToTop() {
			window.scrollTo(0, 0);
		},
	},
	created() {
		this.scroollToTop();
	},
};
</script>

<style></style>
