<template>
	<div class="contactus">
		<ContactUs />
	</div>
</template>
<script>
import ContactUs from "@/components/ContactUs.vue"

export default {
	name: "Update",
	components: {
		ContactUs
	},
};
</script>

<style></style>
