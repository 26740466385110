<template>
	<div class="profile">
        <Profile />
	</div>
</template>

<script>
import Profile from "@/components/Profile.vue"

export default {
	name: "ProfileVue",
	components: {
        Profile
	},
};
</script>

<style></style>
