<template>
	<div class="privacypolicy">
		<PrivacyPolicy/>
	</div>
</template>
<script>
import PrivacyPolicy from "@/components/PrivacyPolicy.vue";


export default {
	name: "Update",
	components: {
		PrivacyPolicy
	},
};
</script>
<style></style>
