<template>
	<div class="container-fluid mt-5">
		<div class="container">
			<h3 class="bold-stella" style="color:black">Wishlist</h3>
			<div class="Wishlist">
				<div class="input-group mb-3">
					<input
						v-model="allProdukSearch"
						type="text"
						class="form-control txt-search"
						placeholder="Cari Produk"
						aria-label="Cari Produk"
						aria-describedby="basic-addon1"
					/>
					<span class="input-group-text logo-search"
						><b-icon-search></b-icon-search
					></span>
				</div>
				<div class="fluid mb-3">
					<div class="row text-center h-100">
						<!-- loop prod dari sini -->
						<div
							:class="{ hilang: notfound }"
							class="container"
							style="height: 500px;"
						>
							No data
						</div>
						<div
							v-for="newProdukAll in filterProdukAll"
							:key="newProdukAll.id_produk"
							:class="{ hilang: afterLoading }"
							class="col-12 col-lg-3  text-center card-produk-stella"
						>
							<div class="card box8 ">
								<div class="card-header p-love">
									<a>
										<b-icon-heart-fill
										></b-icon-heart-fill>
									</a>
								</div>
								<div class="produk-card-p-w">
									<div class="mt-auto">
										<img
											:src="
												'https://stellacms.stella-airfreshener.com/' +
													newProdukAll.url_foto_produk
											"
											width="25%"
										/>
									</div>
								</div>
								<div class="card-footer-p-s text-muted text-p-ls">
									<div class="">
										<p class="text-produk-mix-p">
											{{ newProdukAll.nama_produk }}
										</p>
									</div>
									<div class="box-content">
										<router-link :to="'produk-detail/' + newProdukAll.slug"
											>Lihat Selengkapnya</router-link
										>
									</div>
								</div>
							</div>
						</div>
						<!-- sampai sini  -->
					</div>
					<div :class="{ hilang: loading }" class="col-12 col-lg-12 text-center">
						<div class="">
							<img
								class="card-img"
								src="../assets/images/testing/load.gif"
								style="width: 10%;!important"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { HTTP } from "../config/http-common.js";

export default {
	name: "Wishlist",
	data() {
		return {
			produkAllNew: [],
			allProdukSearch: "",
			loading: false,
			afterLoading: true,
			notfound: true,
		};
	},
	methods: {
		scroollToTop() {
			window.scrollTo(0, 0);
		},
		setProdukAll(data) {
			this.produkAllNew = data;
			this.loading = true;
			this.afterLoading = false;
		},
	},
	created() {
		this.scroollToTop();
		HTTP.get("produkwishlist", {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("token"),
			},
		})
			.then((response) => {
				// console.log(response.data.length)
				if (response.data.length != 0) {
					this.setProdukAll(response.data);
				} else {
					this.loading = true;
					this.notfound = false;
				}
			})
			// .then((response)=> console.log(response.data))
			.catch((error) => console.log(error));
	},
	computed: {
		filterProdukAll: function() {
			return this.produkAllNew.filter((newProdukAll) => {
				return newProdukAll.nama_produk
					.toLowerCase()
					.match(this.allProdukSearch.toLowerCase());
			});
		},
	},
};
</script>

<style></style>
