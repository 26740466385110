<template>
	<div class="container">
		<div class="row">
			<div
				:class="{ hilang: beforeRegister }"
				class="align-items-center justify-content-center col-12 col-lg-6"
			>
				<div class="container formBox">
					<H3 class="bold-stella" style="color:black">Masuk</H3>
					<form class="mt-5 mb-5 formSubs" @submit="postLogin">
						<div class="row">
							<div class="col-lg-12">
								<div class="inputBox">
									<div class="inputForm">Email</div>
									<input
										placeholder="email"
										type="text"
										name=""
										class="input"
										v-model="posts.email"
									/>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-lg-12">
								<div class="inputBox">
									<div class="inputForm">Kata sandi</div>
									<input
										placeholder="password"
										type="password"
										name=""
										class="input"
										v-model="posts.password"
									/>
								</div>
							</div>
						</div>

						<div class="text-center">
							<input type="submit" name="" class="btnLogin" value="Masuk" />
						</div>
					</form>
					<div class="container text-center">
						<!-- <p>OR</p>
						<div class="icon-pos">
							<b-icon-facebook
								style="width: 80px; height: 80px;"
								class="icon-login"
							></b-icon-facebook>
							<b-icon-instagram
								style="width: 80px; height: 80px;"
								class="icon-login"
							></b-icon-instagram>
						</div> -->
						<p class="txt-login">
							Belum punya akun?
							<a class="txt-login" href="#" @click="tmbhUsr">Daftar</a>
						</p>
					</div>
				</div>
			</div>
			<div :class="{ hilang: loading }" class="col-12 col-lg-6 mt-5">
				<img class="img-load" src="../assets/images/testing/load.gif" />
			</div>
			<div
				:class="{ hilang: afterRegister }"
				class="align-items-center justify-content-center col-12 col-lg-6"
			>
				<div class="container formBox">
					<H3 class="bold-stella" style="color:black">Daftar</H3>
					<form class="mt-5 mb-5 formSubs" @submit="postReg">
						<div class="row">
							<div class="col-lg-12">
								<div class="inputBox">
									<div class="inputForm">Nama</div>
									<input
										placeholder="nama"
										type="text"
										name=""
										class="input"
										v-model="reg.nama"
									/>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-lg-12">
								<div class="inputBox">
									<div class="inputForm">Email</div>
									<input
										placeholder="email"
										type="text"
										name=""
										class="input"
										v-model="reg.email"
									/>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-lg-12">
								<div class="inputBox">
									<div class="inputForm">Password</div>
									<input
										placeholder="password"
										type="password"
										name=""
										class="input"
										v-model="reg.password"
									/>
								</div>
							</div>
						</div>

						<div class="text-center">
							<input type="submit" name="" class="btnLogin" value="Daftar" />
						</div>
					</form>
					<div class="container text-center">
						<p class="txt-login">
							Sudah punya akun?
							<a class="txt-login" href="#" @click="LogUsr">Masuk</a>
						</p>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-6 mt-5 img-mobile-login">
				<img
					class="card-img"
					src="../assets/images/testing/asset_login.png"
					width="10%"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { HTTP } from "../config/http-common.js";
export default {
	name: "LoginPage",

	data() {
		return {
			posts: {
				email: null,
				password: null,
			},
			reg: {
				email: null,
				nama: null,
				password: null,
			},
			loading: true,
			// afterSubs: true,
			// beforeSubs: false,
			beforeRegister: false,
			afterRegister: true,
		};
	},
	methods: {
		postLogin(data) {
			this.loading = false;
			this.beforeRegister = true;
			console.log(this.posts)
			HTTP.post("login", this.posts)
				// .then((response) => this.setArtikelHome(response.data))
				// jika sukses
				.then((response) => {
					// console.log(response.data.success.token);
					if (response.status == 200) {
						// this.afterSubs = false;
						// this.beforeSubs = true;
						// this.loading = true;

						localStorage.setItem("token", response.data.success.token);
						localStorage.setItem("nama", response.data.success.name);

						// this.$router.push("/");
						
					window.location.href = "/";
					}
				})
				// jika gagal
				.catch((error) => {
					console.log(error.response.status);
					this.loading = true;
					this.beforeRegister = false;
					this.$toast.error(
						"Anda gagal login. password atau email tidak sesuai.",
						{
							type: "error",
							position: "top-right",
							duration: 3000,
							dismissible: true,
						}
					);
				});
			data.preventDefault();
		},
		tmbhUsr() {
			this.beforeRegister = true;
			this.afterRegister = false;
		},
		LogUsr() {
			this.beforeRegister = false;
			this.afterRegister = true;
		},
		postReg(dataReg) {
			this.loading = false;
			this.afterRegister = true;
			const registerUser = {
				email: this.reg.email,
				name: this.reg.nama,
				password: this.reg.password,
				c_password: this.reg.password,
				is_subscribe: 0,
			};
			// console.log(registerUser);
			HTTP.post("register", registerUser)
				// jika sukses
				.then((response) => {
					if (response.status == 200) {
						this.$toast.success("Terima kasih. Anda berhasil mendaftar. harap login dahulu. ", {
							type: "success",
							position: "top-right",
							duration: 3000,
							dismissible: true,
						});
						this.loading = true;
						this.beforeRegister = false;
						this.afterRegister = true;
					}
				})
				// jika gagal
				.catch((error) => {

					var psn_error = "";
					var psn = "";
					if (typeof error.response.data.error === "string") {
						psn_error = error.response.data.error;
					} else {
						var er = "";
						for (var x in error.response.data.error) {
							if (x == "name") {
								psn = "nama";
							} else if (x == "email") {
								psn = ",email";
							}

							// var psn = error.response.data.error[x][0]
							er += psn;
						}
						psn_error = er + " tidak boleh kosong";
					}
					this.loading = true;
					this.afterRegister = false;
					this.$toast.error("Anda gagal register. " + psn_error, {
						type: "error",
						position: "top-right",
						duration: 3000,
						dismissible: true,
					});
				});
			dataReg.preventDefault();
		},
	},
	created() {
		if (localStorage.getItem("token") != null) {
			this.$router.push("/");
		} else {
			console.log("tetap");
		}
	},
};
</script>

<style></style>
