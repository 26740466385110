<template>
  <div id="app">
    <Navbar v-if="$route.path!='/sitemap.xml/artikel'" />
    <router-view/>
    <Footer v-if="$route.path!='/sitemap.xml/artikel'"/>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';

export default {
  components: {
    Navbar,
    Footer
  },
}
</script>