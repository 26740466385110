<template>
	<div>
		<div
			style="margin-top: 5%;margin-bottom: 5%"
			class="loading text-center mt-10"
			:class="{ hilang: loading }"
		>
			<img src="../assets/images/testing/load.gif" width="5%" />
		</div>
		<b-carousel
			class="mobile-view"
			id="carousel-fade"
			:interval="4000"
			controls
			indicators
			img-width="1024"
			img-height="40"
			style="text-shadow: 1px 1px 2px #333;"
		>
			<div
				:class="{ hilang: false }"
				v-for="banner in banners"
				:key="banner.id_banner"
			>
				<b-carousel-slide :img-src="banner.url_image_banner">
				</b-carousel-slide>
			</div>
		</b-carousel>
		<!-- mobile -->
		<b-carousel
			class="web-view"
			id="carousel-fade"
			:interval="4000"
			controls
			indicators
			img-width="1024"
			img-height="40"
			style="text-shadow: 1px 1px 2px #333;"
		>
			<div
				:class="{ hilang: false }"
				v-for="banner in banners"
				:key="banner.id_banner"
			>
				<b-carousel-slide :img-src="banner.url_image_banner_mobile">
				</b-carousel-slide>
			</div>
		</b-carousel>
	</div>
</template>

<script>
import { HTTP } from "../config/http-common.js";
// import axios from "axios";
export default {
	name: "BannerHome",
	data() {
		return {
			banners: [],
			loading: false,
		};
	},
	methods: {
		setBanners(data) {
			this.banners = data;
			this.loading = true;
		},
	},
	mounted() {
		HTTP.get("banner_all")
			.then((response) => this.setBanners(response.data))
			// .then((response)=> console.log(response.data))
			.catch((error) => console.log(error));
	},
};
</script>

<style></style>
