<template>
	<div class="fluid mt-3 mb-3">
		<H2 class="text-center bold-stella jdl-prod">Ada Apa di Stella</H2>
		<p class="text-center ph-prod">
			Temukan berbagai informasi menarik, berita seru<br />
			serta promo terbaru di sini
		</p>
		<div class="container">
			<div class="container col-lg-12">
				<div class="row d-flex justify-content-center text-center h-100 ">
					<div
						v-for="newArtikel in artikelNew"
						:key="newArtikel.id_artikel"
						:class="{ hilang: afterLoading }"
						class="col-lg-3 col-6 text-center card-col-artikel "
					>
						<router-link
							class="shadow card-artikel box7 nav-link link-artikel"
							:to="'artikel/' + newArtikel.slug"
						>
							<div class="card-add">
								<div class="card-body text-left ">
									<p class="card-title artikel-judul-list">
										{{ newArtikel.nama_kategori_artikel }}
									</p>
									<img
										class="card-img"
										:src="newArtikel.url_gambar_artikel"
										width="25%"
									/>
									<p class="card-title artikel-judul-list">
										{{ newArtikel.judul_artikel }}
									</p>
									<p class="web-view card-text artikel-desk-list">
										{{ newArtikel.meta_desc.substring(0,50 )+"...."  }}
									</p>
									<p align="justify" class="mobile-view card-text artikel-desk-list">
										{{ newArtikel.meta_desc.substring(0,140)+"...."  }}
									</p>
								</div>
								<div class="card-artikel-footer">
									<small class="text-muted">{{
										newArtikel.tanggal_publish
									}}</small>
									<small class="text-muted"
										><router-link :to="'artikel/' + newArtikel.slug"
											>Selengkapnya<b-icon-arrow-right
											></b-icon-arrow-right></router-link
									></small>
								</div>
							</div>
							<div class="box-content">
								<div class="card-content-show card-artikel-first ">
									<div class="card-body ">
										<p class="text-left first-pesan">
											{{ newArtikel.judul_artikel }}
										</p>
										<p class="text-left pesan-tanggal">
											{{ newArtikel.tanggal_publish }}
										</p>

										<p class="text-left first-desk">
											{{ newArtikel.meta_desc }}
										</p>
									</div>
								</div>
							</div>
						</router-link>
					</div>

					<div
						:class="{ hilang: loading }"
						class="col-sm-4 text-center card-col-artikel "
					>
						<div class="card shadow card-artikel artikel-list">
							<div class="card-body text-left ">
								<p class="info-skel">tips</p>
								<img
									class="card-img"
									src="../assets/images/testing/load.gif"
									width="10%"
								/>
								<p class="info-skel">
									Judul
								</p>
							</div>
						</div>
					</div>
					<!-- <div class="col-lg-4 text-center card-col-artikel">
						<div class="card  shadow card-artikel-end">
							<div class="card-header-artikel">
								<div class="text-right">
									<router-link to="/"
										><b-icon-plus-circle-fill></b-icon-plus-circle-fill
									></router-link>
								</div>
							</div>
							<div
								class="card-body align-items-center d-flex justify-content-center ar-mobile"
							></div>
							<div class="card-footer-all-artikel">
								<p class="text-left artikel-card-plus-look">
									Lihat Semua
								</p>
								<p class="text-left artikel-card-plus">
									Ada Apa Di stella.
								</p>
								<small class="text-muted"
									><router-link to="/tips"
										>Yuk, baca artikelnya!
										<b-icon-arrow-right></b-icon-arrow-right></router-link
								></small>
							</div>
						</div>
					</div> -->
				</div>

				<div class="text-center mb-lg-4">
					<router-link to="/tips" class="btnartikelmore">
						Lihat Lebih banyak</router-link
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { HTTP } from "../config/http-common.js";
export default {
	name: "ArtikelHome",
	data() {
		return {
			artikelNew: [],
			loading: false,
			afterLoading: true,
		};
	},
	methods: {
		setArtikelHome(data) {
			this.artikelNew = data;
			this.loading = true;
			this.afterLoading = false;
		},
	},
	mounted() {
		HTTP.get("artikel_all_limit")
			.then((response) => this.setArtikelHome(response.data))
			// .then((response)=> console.log(response.data))
			.catch((error) => console.log(error));
	},
};
</script>

<style></style>
