import Vue from 'vue'
import Vuex from  'vuex'


Vue.use(Vuex)

const state ={
    userLogin : null
};

const store = new Vuex.Store({
    state,
    getters:{
        userLogin: (state) =>{
            return state.userLogin;
        }
    },
    actions:{
        userLogin(context, userLogin){
            context.commit('userLogin', userLogin);
        }
    },
    mutations:{
        userLogin(state, userLogin){
            state.userLogin = userLogin;
        }
    }

})

export default store;