<template>
	<div class="fluid">
		<div class="container-fluid">
			<div :class="{ hilang: loading }" class="col-12 col-lg-12 text-center">
				<img
					class="card-img"
					src="../assets/images/testing/load.gif"
					style="width: 10%;!important"
				/>
			</div>
		</div>
		<div :class="{ hilang: afterLoading }" class="fluid">
			<div class="banner-detail-produk">
				<img :src="detailProduk.url_foto_banner_produk" width="100%" />
			</div>
			<div class="container mt-3">
				<b-container class="bv-example-row col-12 col-lg-12">
					<b-row>
						<b-col
							v-if="detailProduk.foto_landscape == 1"
							class="row text-center"
						>
							<div class="card-produk-detail ptr col-10 col-lg-3">
								<img :src="detailProduk.url_foto_produk" />
							</div>
							<div class="card-produk-detail ptr in-mobile col-lg-3">
								<img :src="detailProduk.url_foto_produk" />
							</div>
							<div class="card-produk-detail ptr in-mobile col-lg-3">
								<img :src="detailProduk.url_foto_produk" />
							</div>
						</b-col>
						<b-col
							v-else-if="detailProduk.foto_landscape == 2"
							class="row text-center"
						>
							<div class="card-produk-detail sqr col-10 col-lg-3">
								<img :src="detailProduk.url_foto_produk" />
							</div>
							<div class="card-produk-detail sqr in-mobile col-lg-3">
								<img :src="detailProduk.url_foto_produk" />
							</div>
							<div class="card-produk-detail sqr in-mobile col-lg-3">
								<img :src="detailProduk.url_foto_produk" />
							</div>
						</b-col>
						<b-col
							v-else-if="detailProduk.foto_landscape == 3"
							class="row text-center"
						>
							<div class="card-produk-detail lsc col-10 col-lg-3">
								<img :src="detailProduk.url_foto_produk" />
							</div>
							<div class="card-produk-detail lsc in-mobile col-lg-3">
								<img :src="detailProduk.url_foto_produk" />
							</div>
							<div class="card-produk-detail lsc in-mobile col-lg-3">
								<img :src="detailProduk.url_foto_produk" />
							</div>
						</b-col>
						<b-col class="row">
							<div class="col-12 detail-text-produk">
								<h3 class="jdl-prod-nama">
									{{ detailProduk.nama_produk }}
								</h3>
								<p>
									{{ detailProduk.desc_produk }}
								</p>
							</div>
							<div class="p-d-love beli-btn col-12">
								<div class="row">
									<div v-if="is_like != null" class="card-love-detail col-2 col-lg-1 h2 ">
										<b-icon-heart></b-icon-heart>
									</div>
									<div v-else class="">
									</div>
									<div class="col-10 col-lg-6 btnBeliSekarang">
										<button @click="$bvModal.show('modal-store-produk')">
											<b-icon-cart3></b-icon-cart3> Beli Sekarang
										</button>
									</div>
								</div>
							</div>
						</b-col>
					</b-row>
				</b-container>
			</div>
			<div class="container mt-5">
				<div class="col-12 des-text-produk">
					<h3 class="jdl-prod">Deskripsi Produk</h3>
					<p align="justify" class="ph-prod">
						{{ detailProduk.seo_desc }}
					</p>
				</div>
			</div>
			<div class="container mt-5">
				<div class="col-12 des-text-produk">
					<h3 class="jdl-prod">Produk Terkait</h3>

					<div class="fluid mb-3">
						<div class="row text-center h-100">
							<!-- loop prod dari sini -->
							<div
								v-for="newRelate in relateProduk"
								:key="newRelate.id_produk"
								:class="{ hilang: afterLoadingRelate }"
								class="col-6 col-lg-3 text-center card-produk-stella"
							>
								<router-link :to="'/produk-detail/' + newRelate.slug">
									<div class="card">
										<div class="card-header p-love">
											<!-- <b-icon-heart></b-icon-heart> -->
										</div>
										<div class="produk-card-p-s">
											<div class="mt-auto">
												<img
													:src="
														'https://stellacms.stella-airfreshener.com/' +
															newRelate.url_foto_produk
													"
													width="25%"
												/>
											</div>
										</div>
										<div class="card-footer-p-s text-muted text-p-ls">
											<div class="">
												<p class="text-produk-mix">
													{{ newRelate.nama_produk }}
												</p>
											</div>
										</div>
									</div>
								</router-link>
							</div>
							<!-- sampai sini  -->
						</div>
						<div
							:class="{ hilang: loadingRelate }"
							class="col-12 col-lg-4 text-center"
						>
							<div class="">
								<img
									class="card-img"
									src="../assets/images/testing/load.gif"
									style="width: 10%;!important"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<b-modal id="modal-store-produk" centered hide-header hide-footer>
				<div class="d-block text-center store-belanja">
					<h3 class="bold-stella">SHOP</h3>
					<p>OFFICIAL STORE PRODUK</p>
					<div class="container">
						<div class="row">
							<div
								v-for="newSP in detailProduk.ecommerce_produk"
								:key="newSP.id_ecommerce"
								class="col-lg-3 col-3 text-center"
							>
								<a
									class="navbar-brand tengah-store"
									:href="'//' + newSP.link_ecommerce_official"
								>
									<img
										:src="
											'https://stellacms.stella-airfreshener.com/' + newSP.link_logo_official
										"
										width="100%"
									/>
								</a>
								<p>{{ newSP.nama_ecommerce }}</p>
							</div>
						</div>
					</div>
				</div>
			</b-modal>
		</div>
	</div>
</template>

<script>
import { HTTP } from "../config/http-common.js";
export default {
	name: "ProdukDetail",
	data() {
		return {
			is_like: localStorage.getItem("token"),
			detailProduk: {},
			loading: false,
			afterLoading: true,
			relateProduk: [],
			loadingRelate: false,
			afterLoadingRelate: true,
		};
	},
	methods: {
		setProdukDetail(data) {
			this.detailProduk = data;
			this.loading = true;
			this.afterLoading = false;
		},
		setProdukRelate(dataRelate) {
			this.relateProduk = dataRelate;
			this.loadingRelate = true;
			this.afterLoadingRelate = false;
		},
		scroollToTop() {
			window.scrollTo(0, 0);
		},
		getData() {
			HTTP.get("/detailproduk/" + this.$route.params.slug)
				.then((response) => this.setProdukDetail(response.data))
				// .then((response) => console.log(response))
				.catch((error) => console.log(error));
			HTTP.get("/relateproduk/" + this.$route.params.slug)
				.then((response) => this.setProdukRelate(response.data))
				// .then((response) => console.log(response))
				.catch((error) => console.log(error));

			this.scroollToTop();
		},
	},
	created() {
		this.getData();
	},
	watch: {
		"$route.params.slug"() {
			this.loading = false;
			this.afterLoading = true;
			this.loadingRelate = false;
			this.afterLoadingRelate = true;
			this.getData();
			// console.log(to);
			// console.log(from);
		},
	},
};
</script>

<style></style>
