<template>
	<div class="voucher">
        <Voucher />
	</div>
</template>

<script>
import Voucher from "@/components/Voucher.vue"

export default {
	name: "PageVoucher",
	components: {
        Voucher
	},
};
</script>

<style></style>
