<template>
	<div class="tips">
        <TipsContent />
	</div>
</template>

<script>
import TipsContent from "@/components/TipsContent.vue"

export default {
	name: "Tips",
	components: {
        TipsContent
	},
};
</script>

<style></style>
