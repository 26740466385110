<template>
	<div class="detailproduk">
        <ProdukDetail />
	</div>
</template>
<script>
import ProdukDetail from "@/components/DetailProduk.vue";



export default {
	name: "DetailProduk",
	components: {
        ProdukDetail
	},
};
</script>
<style></style>
